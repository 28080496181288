/* TODO - ideally this would be more reusable and not hardcode a value */
@keyframes progress-bar {
  from {
    transform: translate3d(0, 0, 0, 0);
  }

  to {
    transform: translate3d(1000px, 0, 0);
  }
}

.ProgressBarAnimation {
  animation: progress-bar 1.5s linear infinite;
}

.ProgressBarNoAnimation {
  animation: none;
}

.SpinOut {
  transform-style: preserve-3d;
  transform-origin: center;
  transition: transform 1s;
}

.SpinOutActive {
  transform: rotateY(180deg);
}

@keyframes pop-in-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pop-toast {
  from {
    opacity: 0;
    transform: translate(-50%, 100px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.popInFromBottom {
  animation: 0.15s ease-out 0s 1 pop-in-from-bottom;
  transform-origin: center center;
}

.slideLeft {
  animation: 0.15s ease-out 0s 1 slide-left;
}

.popToast {
  animation: 0.15s ease-out 0s 1 pop-toast;
}

.fadeIn {
  animation: 0.15s ease-out 0s 1 fade-in;
  transform-origin: center center;
}

/* This improves a11y and helps e2e testing */
@media (prefers-reduced-motion: reduce) {
  .ProgressBarAnimation,
  .popInFromBottom,
  .slideLeft,
  .popToast,
  .fadeIn {
    animation: none;
  }
}
